<template>
<div class="container-fluid">
    <div class="row">
        <sidebar></sidebar>
        <!-- MAIN CONTENT start -->
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
            <div class="row">
                <div class="col-12 mb-5">
                    <app-header></app-header>
                    <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                </div>
            </div>
            <!-- Program-fee -->
            <section class="px-0 mx-0 px-lg-5 mx-lg-5  mt-0 mt-md-5">
              <div class="bg-light b-30">
                <div class="p-5 shadow-1 justify-content-start my-5 mt-md-5 mt-lg-0 pt-sm-0">
                  <div class="py-5 mx-auto w-25">
                    <pay-online-form />
                  </div>
                </div>
              </div>
            </section>
        </main>

    </div>
</div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import SkList from '../components/skeletons/sk-list';
import PayOnlineForm from '../components/pay-online-form';
import Multiselect from 'vue-multiselect';


export default {
    name:'pay-online',
    components: { PayOnlineForm, SkList, sidebar, appHeader, pageHeader, Multiselect },
    computed: {
      pageData() {
        return {
          title: this.$t('financial-info.pay-online'),
            breadcrumb: [
              {
                icon: '',
                title: this.$t('home.title'),
                link: '/',
                isActive: false,
              },
              {
                icon: '',
                title: this.$t('financial-info.pay-online'),
                link: '/pay-online',
                isActive: true,
              }
          ],
        };
      },
    },
}
</script>
